export enum AppSettings {
    reportsNew = 'reportsNew',
    feedPostTile = 'feedPostTile',
}

export enum QueryCacheKeys {
    basket = 'basket',
    post = 'post',
    calendar = 'calendar',
    contacts = 'contacts',
    cities = 'cities',
    blocks = 'blocks',
    subscription = 'subscription',
    conversationList = 'conversation-list',
    conversationCountsNew = 'conversation-counts-new',
    channel = 'channel',
    channelMembers = 'channel-members',
    chatHistoryChat = 'chat-history-chat',
    chatHistoryChannel = 'chat-history-channel',
    chatUserBelongsConversations = 'chat-user-belongs-conversations',
    chatUserBelongsChannels = 'chat-user-belongs-channels',
    chatUserBelongsMessages = 'chat-user-belongs-messages',
    profile = 'profile',
    profileFriends = 'profile-friends',
    friends = 'friends',
    friendsStore = 'friends-store',
    friendsRequest = 'friends-request',
    friendsSearch = 'friends-search',
    friendsChatSearch = 'friends-chat-search',
    filterAccounts = 'filter-accounts',
    usersData = 'users-data',
    marketCategories = 'marketCategories',
    subscribe = 'subscribe',
    shops = 'shops',
    shopsStatic = 'shops-static',
    storesProfiles = 'stores-profiles',
    storeSettings = 'store-settings',
    storeRootCatalog = 'store-root-catalog',
    storeCatalog = 'store-catalog',
    storeShowcase = 'store-showcase',
    storeProfile = 'store-profile',
    storeRecommendedGoods = 'store-recommended-goods',
    storePopularGoods = 'store-popular-goods',
    ratingTypeSum = 'rating-type-sum',
    ratingList = 'rating-list',
    reports = 'reports',
    ticket = 'ticket',
    tickets = 'tickets',
    order = 'order',
    payoutHistory = 'payoutHistory',
    payoutOption = 'payoutOption',
    mlmReports = 'mlm-reports',
    mlmReportsUsers = 'mlm-reports-users',
    mlmReportsRanksCurrent = 'mlm-reports-ranks-current',
    mlmReportsRanksCurrentUsers = 'mlm-reports-ranks-current-users',
    mlmReportsRanksMax = 'mlm-reports-ranks-max',
    mlmReportsRanksMaxUsers = 'mlm-reports-ranks-max-users',
    mlmReportsRanksNew = 'mlm-reports-ranks-new',
    mlmReportsRanksNewUsers = 'mlm-reports-ranks-new-users',
    mlmReportsClientsGraph = 'mlm-reports-clients-graph',
    mlmReportsIncomeGraph = 'mlm-reports-income-graph',
    mlmReportsLoginGraph = 'mlm-reports-login-graph',
    mlmReportsActivePartnersGraph = 'mlm-reports-active-partners-graph',
    mlmReportsTurnoverGraph = 'mlm-reports-turnover-graph',
    mlmReportsTurnoverProducts = 'mlm-reports-turnover-products',
    mlmReportsIncomeList = 'mlm-reports-income-list',
    mlmRankUsers = 'mlm-rank-users',
    mlmRankStats = 'mlm-rank-stats',
    mlmPvBusinessPack = 'mlm-pv-business-pack',
}

export enum PhoneDataFields {
    countryId = 'id',
    countryCode = 'code',
    phone = 'value',
}

export enum SmsCodeField {
    smsCode = 'smsCode'
}

export enum CardSize {
    fullWidth = 'fullWidth',
    halfWidth = 'halfWidth',
    thirdWidth = 'thirdWidth',
    fourthWidth = 'fourthWidth',
}

export enum ChartPeriod { all, year, month, week }

export enum ChartPeriodTranslate {
    ui_charts_period_all,
    ui_charts_period_year,
    ui_charts_period_month,
    ui_charts_period_week,
}

export enum MlmChartType {
    clients= 'clients',
    activePartners= 'active-partners',
    turnover= 'turnover',
    income= 'income',
}

export enum AppScheme {
    pv = 'pv',
    cc = 'cc',
}

export enum DatePeriod {
    hour = 'hour',
    day = 'day',
    week = 'week',
    month = 'month',
    year = 'year',
    all = 'all',
    custom = 'custom',
}

export enum Lines {
    all = 0,
    one = 1,
    two = 2,
    three = 3,
    four = 4,
    five = 5,
    six = 6,
}

export enum MenuNavChild {
    byQueryParams = 'byQueryParams',
    default = 'default',
}

export enum FeedTabsId {
    allPosts,
    userPosts,
    friends,
    companyPosts,
    leadersPosts,
    presidentPosts,
}

export enum MessengerMessageType {
    chatStatus = 'CHAT_STATUS',
    status = 'MESSAGE_STATUS',
    massStatus = 'MESSAGES_STATUS',
    message = 'NEW_MESSAGE',
    forward = 'MESSAGE_FORWARD',
    reply = 'MESSAGE_REPLY',
    error = 'ERROR',
    chat = 'P2P',
    channel = 'P2C',
}

export enum MessengerMessageStatus {
    delivered = 'DELIVERED',
    deleted = 'DELETED',
    read = 'READ',
    unread = 'UNREAD',
}

export enum FriendFilterSortBy {
    posts_count = 'posts_count',
    rating = 'rating',
}
