import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    IMlmRankUser,
    ICountry,
    ICity,
    IBreadcrumb,
} from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod, Lines } from 'enums'
import { APP_URL } from 'config/app'
import { CardContainer, Block } from 'layout'
import {
    PageTitle,
    Breadcrumbs,
    Loader,
    DateRangeFilter,
    InfiniteScroll,
    CountryDataSelect,
    CityDataSelect,
    SvgResource,
    Modal,
    Button,
    NoDataInfo,
} from 'components'
import { ReportsRankUsersListItem } from 'containers/Reports/components'
import { ReportsFreedomChart } from 'containers/ReportsFreedom/components'
import { useFetchMlmReportsUsers, useFetchMlmReportsClientsGraph } from 'containers/ReportsFreedom/hooks'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { numberFormat, plural } from 'utils/helpers'
import { ReportsFreedomClientsLineFilter } from './components'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'
import style from './ReportsFreedomClients.module.css'

const ReportsFreedomClients: React.FC = () => {
    const { t } = useTranslation()

    const user = useSelector(userSelectors.user)
    const countries = useSelector(appSelectors.countries)

    const [lineFilter, setLineFilter] = useState<Lines>()
    const [dateFilter, setDateFilter] = useState<DatePairType>()
    const [countryCityFilter, setCountryCityFilter] = useState<{ country?: ICountry, city?: ICity }>({
        country: undefined,
        city: undefined,
    })
    const [countryCityState, setCountryCityState] = useState<{ country?: ICountry, city?: ICity }>({
        country: user?.country,
        city: undefined,
    })
    const [datePeriod, setDatePeriod] = useState<DatePeriod>()
    const [userList, setUserList] = useState<IMlmRankUser[]>([])
    const [limit] = useState(15)
    const [offset, setOffset] = useState(0)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isResetUserListPrevData, setIsResetUserListPrevData] = useState(false)
    const [isHideInfiniteLoader, setIsHideInfiniteLoader] = useState(false)
    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: t('clients') },
    ])

    const {
        isLoading: isLoadingReportsClientsGraph,
        data: dataMlmReportsClientsGraph,
    } = useFetchMlmReportsClientsGraph({
        dateFrom: dateFilter?.dateFrom,
        dateTo: dateFilter?.dateTo,
    }, {
        keepPreviousData: true,
        enabled: !!dateFilter,
    })

    const {
        isInitialLoading: isLoadingReportsUsers,
        data: dataReportsUsers,
    } = useFetchMlmReportsUsers({
        line: lineFilter,
        limit,
        offset,
        countryId: countryCityFilter.country?.id,
        cityId: countryCityFilter.city?.id,
    }, {
        enabled: !isLoadingReportsClientsGraph,
    })

    const chartTotalClients = useMemo(() => {
        const { total } = dataMlmReportsClientsGraph || {}
        return total !== undefined
            ? `${numberFormat(total)} ${plural(total, [t('client_1x'), t('client_2x'), t('client_5x')])}`
            : undefined
    }, [dataMlmReportsClientsGraph])

    const usersTotal = useMemo(() => {
        return dataReportsUsers ? numberFormat(dataReportsUsers.count) : undefined
    }, [dataReportsUsers])

    const handlerChangeDateFilter = (dates: DatePairType, period: DatePeriod) => {
        setDateFilter(dates)
        setDatePeriod(period)
    }

    const handlerChangeLineFilter = (line?: Lines) => {
        setLineFilter(line)
        resetUserList()
    }

    const handlerClickModal = () => {
        setIsOpenModal((prevState) => !prevState)
    }

    const handlerClickCountry = (country: ICountry) => {
        if (country) {
            setCountryCityState({ country, city: undefined })
        }
    }

    const handlerClickCity = (city: ICity) => {
        if (city) {
            setCountryCityState((prevState) => ({ ...prevState, city }))
        }
    }

    const handlerOpenCity = () => {
        if (!countryCityState.country) {
            setCountryCityState(((prevState) => ({ ...prevState, country: user?.country })))
        }
    }

    const handlerClickResetField = ({ currentTarget }: React.SyntheticEvent<HTMLButtonElement>) => {
        if (currentTarget.name === 'country') {
            setCountryCityState({ country: undefined, city: undefined })
        } else {
            setCountryCityState((prevState) => ({ ...prevState, [currentTarget.name]: undefined }))
        }
    }

    const handlerClickCountryCityFilterSubmit = () => {
        const { country, city } = countryCityState

        if (city) {
            setCountryCityFilter({ country: undefined, city })
        } else if (country) {
            setCountryCityFilter({ country, city: undefined })
        } else {
            setCountryCityFilter({ country: undefined, city: undefined })
        }

        resetUserList()
        setIsOpenModal(false)
    }

    const handlerOnLoad = () => {
        if (!isLoadingReportsUsers) {
            setOffset((prevSate) => prevSate + limit)
        }
    }

    function resetUserList() {
        setIsResetUserListPrevData(true)
        setOffset(0)
        setIsHideInfiniteLoader(false)
    }

    useEffect(() => {
        if (dataReportsUsers) {
            const { data } = dataReportsUsers

            setUserList((prevState) => {
                return isResetUserListPrevData ? data : [...prevState, ...data]
            })
            setIsResetUserListPrevData(false)

            if (data.length < limit) {
                setIsHideInfiniteLoader(true)
            }
        }
    }, [dataReportsUsers])

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {dataMlmReportsClientsGraph?.title}
                    </div>
                </Block.Header>
                <DateRangeFilter
                    activeColor={dataMlmReportsClientsGraph?.color}
                    onChange={handlerChangeDateFilter}
                />
                <div className={style.chartTotal} style={{ color: dataMlmReportsClientsGraph?.color }}>
                    {chartTotalClients}
                </div>
                {isLoadingReportsClientsGraph && (
                    <Loader />
                )}
                {dataMlmReportsClientsGraph && (
                    <ReportsFreedomChart
                        data={dataMlmReportsClientsGraph}
                        period={datePeriod}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {t('report_freedom_lines_title')}
                    </div>
                    <div className={styleReports.blockActions}>
                        <Button
                            classes={style.lineFilterAction}
                            styleType="transparent"
                            disabled={isLoadingReportsUsers}
                            onClick={handlerClickModal}
                        >
                            {t('screen_market_search_filters_title')}
                            <SvgResource
                                resourceKey="ic_report_freedom_filter_svg"
                                width={30}
                                height={30}
                            />
                        </Button>
                    </div>
                </Block.Header>
                <ReportsFreedomClientsLineFilter
                    classes={style.lineFilter}
                    activeColor={dataMlmReportsClientsGraph?.color}
                    isLoading={isLoadingReportsUsers}
                    onChange={handlerChangeLineFilter}
                />
                <div className={style.usersTotal}>
                    {usersTotal !== undefined && (
                        <>
                            {t('report_freedom_partner_count')}
                            {': '}
                            <span className={style.usersTotalCount}>
                                {usersTotal}
                            </span>
                        </>
                    )}
                </div>
                <CardContainer>
                    <InfiniteScroll
                        isActive={!isLoadingReportsClientsGraph && !isLoadingReportsUsers}
                        options={{ rootMargin: '0px 0px 50% 0px' }}
                        isHideLoader={isHideInfiniteLoader}
                        onLoad={handlerOnLoad}
                    >
                        {userList.map((item) => (
                            <ReportsRankUsersListItem
                                classesBody={style.listItemBody}
                                data={item}
                                key={item.id}
                            />
                        ))}
                        {!isLoadingReportsUsers && dataReportsUsers && !dataReportsUsers.data.length && (
                            <NoDataInfo
                                classes={styleReports.listNoData}
                                text={t('nothing_found')}
                            />
                        )}
                    </InfiniteScroll>
                </CardContainer>
            </Block>

            <Modal
                isOpen={isOpenModal}
                size="mediumCenter"
                classes={style.lineFilterModal}
                onClose={handlerClickModal}
            >
                <Modal.Header
                    isCloseButton
                    classesTitle={style.lineFilterModalTitle}
                    title={`${t('select_city')} ${t('or')} ${t('select_country')}`}
                />
                <Modal.Body classes={style.lineFilterModalBody}>
                    <div className={style.lineFilterModalRow}>
                        <div className={style.lineFilterModalLabel}>
                            {countryCityState.country && t('country')}
                        </div>
                        <CountryDataSelect
                            isShowSelectArrow
                            isCanEmpty
                            selectedIsIcon={false}
                            classes={style.lineFilterModalFieldWrap}
                            classesField={style.lineFilterModalField}
                            countries={countries}
                            selected={countryCityState.country}
                            placeholder={t('country')}
                            onChange={handlerClickCountry}
                        />
                        {countryCityState.country && (
                            <Button
                                classes={style.lineFilterModalFieldReset}
                                styleType="transparent"
                                name="country"
                                onClick={handlerClickResetField}
                            />
                        )}
                    </div>
                    <div className={style.lineFilterModalRow}>
                        <div className={style.lineFilterModalLabel}>
                            {countryCityState.city && t('city')}
                        </div>
                        <CityDataSelect
                            classes={style.lineFilterModalFieldWrap}
                            classesField={style.lineFilterModalField}
                            selected={countryCityState.city}
                            countryId={countryCityState.country?.id}
                            countryLang={user.language.iso_code}
                            placeholder={t('city')}
                            onOpen={handlerOpenCity}
                            onChange={handlerClickCity}
                        />
                        {countryCityState.city && (
                            <Button
                                classes={style.lineFilterModalFieldReset}
                                styleType="transparent"
                                name="city"
                                onClick={handlerClickResetField}
                            />
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer classes={style.lineFilterModalFooter}>
                    <div className={style.lineFilterModalRow}>
                        <Button
                            size="size44"
                            text={t('select')}
                            onClick={handlerClickCountryCityFilterSubmit}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ReportsFreedomClients
