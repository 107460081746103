import React, { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { DatePairType } from 'components/DateRangeFilter/DateRangeFilter'
import { DatePeriod } from 'enums'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import { ReportsFreedomChart, ReportsFreedomRankCard } from 'containers/ReportsFreedom/components'
import {
    useFetchMlmReportsRanksCurrent,
    useFetchMlmReportsLoginGraph,
    useFetchMlmReportsActivePartnersGraph,
} from 'containers/ReportsFreedom/hooks'
import {
    PageTitle,
    Breadcrumbs,
    DateRangeFilter,
    Loader,
    NoDataInfo,
    Button,
} from 'components'
import { getMonthDateRange, numberFormat } from 'utils/helpers'
import style from './ReportsFreedomActivePartners.module.css'
import styleReports from '../ReportsFreedom/ReportsFreedom.module.css'

enum DatePeriodRanks {
    current = 'current',
    prev = 'prev',
}

const ACTIVITY_DATE_MIN = '2020-10-08'
const DATE_CURRENT = new Date()
const DATES_MONTH_PREV = getMonthDateRange(new Date(DATE_CURRENT.getFullYear(), DATE_CURRENT.getMonth(), 0))
const DATES_MONTH_CURRENT = getMonthDateRange(DATE_CURRENT)

const ReportsFreedomActivePartners: React.FC = () => {
    const { t } = useTranslation()

    const [datePeriodPartners, setDatePeriodPartners] = useState<DatePeriod>()
    const [datePeriodActivity, setDatePeriodActivity] = useState<DatePeriod>()
    const [datePeriodRanks, setDatePeriodRanks] = useState<DatePeriodRanks>(DatePeriodRanks.current)
    const [dateFilterPartners, setDateFilterPartners] = useState<DatePairType>()
    const [dateFilterActivity, setDateFilterActivity] = useState<DatePairType>()
    const [dateFilterRanks, setDateFilterRanks] = useState<DatePairType>(DATES_MONTH_CURRENT)

    const [breadcrumbs] = useState<IBreadcrumb[]>([
        { id: 1, name: t('reports'), url: APP_URL.reports },
        { id: 2, name: t('active_partners') },
    ])

    const {
        isInitialLoading: isLoadingPartners,
        isFetching: isFetchingPartners,
        data: dataReportsActivePartnersGraph,
    } = useFetchMlmReportsActivePartnersGraph({
        dateFrom: dateFilterPartners?.dateFrom,
        dateTo: dateFilterPartners?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const {
        isInitialLoading: isLoadingActivity,
        isFetching: isFetchingActivity,
        data: dataReportsLoginGraph,
    } = useFetchMlmReportsLoginGraph({
        dateFrom: dateFilterActivity?.dateFrom,
        dateTo: dateFilterActivity?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const { data: dataReportsRanksCurrent } = useFetchMlmReportsRanksCurrent({
        dateFrom: dateFilterRanks?.dateFrom,
        dateTo: dateFilterRanks?.dateTo,
    }, {
        keepPreviousData: true,
    })

    const chartTotalActivePartners = useMemo(() => {
        const { total } = dataReportsActivePartnersGraph || {}
        return total !== undefined
            ? `${numberFormat(total)} ${t('report_freedom_partner_count')}`
            : undefined
    }, [dataReportsActivePartnersGraph])

    const chartTotalLogin = useMemo(() => {
        const { total } = dataReportsLoginGraph || {}
        return total !== undefined
            ? `${numberFormat(total)} ${t('report_freedom_partner_count')}`
            : undefined
    }, [dataReportsLoginGraph])

    const handlerChangeDateFilterPartners = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterPartners(dates)
        setDatePeriodPartners(period)
    }

    const handlerChangeDateFilterActivity = (dates: DatePairType, period: DatePeriod) => {
        setDateFilterActivity(dates)
        setDatePeriodActivity(period)
    }

    const handlerChangeDateFilterRanks = ({ currentTarget }: React.SyntheticEvent<HTMLButtonElement>) => {
        const { name } = currentTarget

        if (name === DatePeriodRanks.current) {
            setDatePeriodRanks(DatePeriodRanks.current)
            setDateFilterRanks(DATES_MONTH_CURRENT)
        }
        if (name === DatePeriodRanks.prev) {
            setDatePeriodRanks(DatePeriodRanks.prev)
            setDateFilterRanks(DATES_MONTH_PREV)
        }
    }

    return (
        <>
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {dataReportsActivePartnersGraph?.title}
                    </div>
                </Block.Header>
                <DateRangeFilter
                    activeColor={dataReportsActivePartnersGraph?.color}
                    onChange={handlerChangeDateFilterPartners}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingPartners },
                    )}
                    style={{ color: dataReportsActivePartnersGraph?.color }}
                >
                    {chartTotalActivePartners}
                </div>
                {isLoadingPartners && (
                    <Loader />
                )}
                {dataReportsActivePartnersGraph && (
                    <ReportsFreedomChart
                        data={dataReportsActivePartnersGraph}
                        period={datePeriodPartners}
                        isLoading={isFetchingPartners}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {dataReportsLoginGraph?.title}
                    </div>
                </Block.Header>
                <DateRangeFilter
                    activeColor={dataReportsLoginGraph?.color}
                    minDate={ACTIVITY_DATE_MIN}
                    onChange={handlerChangeDateFilterActivity}
                />
                <div
                    className={cn(
                        styleReports.chartTotal,
                        { [styleReports.chartTotal_updating]: isFetchingActivity },
                    )}
                    style={{ color: dataReportsLoginGraph?.color }}
                >
                    {chartTotalLogin}
                </div>
                {isLoadingActivity && (
                    <Loader />
                )}
                {dataReportsLoginGraph && (
                    <ReportsFreedomChart
                        data={dataReportsLoginGraph}
                        period={datePeriodActivity}
                        isLoading={isFetchingActivity}
                    />
                )}
            </Block>
            <Block classes={styleReports.block}>
                <Block.Header classes={styleReports.blockHeader}>
                    <div className={styleReports.blockTitle}>
                        {t('reports_freedom_motivate_partners_title')}
                    </div>
                </Block.Header>
                <div className={styleReports.blockSubTitle}>
                    {t('reports_freedom_motivate_partners_description')}
                </div>
                <div className={style.periodsActions}>
                    <Button
                        classes={cn(
                            style.periodsAction,
                            { [style.periodsAction_active]: datePeriodRanks === DatePeriodRanks.current },
                        )}
                        styleType="bordered2"
                        size="size36"
                        name={DatePeriodRanks.current}
                        onClick={handlerChangeDateFilterRanks}
                    >
                        {t('report_freedom_current_month')}
                    </Button>
                    <Button
                        classes={cn(
                            style.periodsAction,
                            { [style.periodsAction_active]: datePeriodRanks === DatePeriodRanks.prev },
                        )}
                        styleType="bordered2"
                        size="size36"
                        name={DatePeriodRanks.prev}
                        onClick={handlerChangeDateFilterRanks}
                    >
                        {t('report_freedom_previous_month')}
                    </Button>
                </div>
                <div className={cn(styleReports.blockBody, styleReports.rankList)}>
                    {dataReportsRanksCurrent?.map((item) => (
                        <NavLink
                            className={cn(
                                styleReports.rankListItem,
                                styleReports.rankListLink,
                            )}
                            to={{
                                pathname: APP_URL.reportsFreedomActivePartnersUsers,
                                state: { rank: item, dateFilter: dateFilterRanks },
                            }}
                            key={item.id}
                        >
                            <ReportsFreedomRankCard
                                data={item}
                            />
                        </NavLink>
                    ))}
                    {!dataReportsRanksCurrent && (
                        <NoDataInfo text={t('nothing_found')} />
                    )}
                </div>
            </Block>
        </>
    )
}

export default ReportsFreedomActivePartners
